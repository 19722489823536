@import "reset";
/* //////////////////////////////////////
- common
////////////////////////////////////// */
body {
	font-family: 'Open Sans', 'Noto Sans JP', sans-serif;
	line-height: 1;
	color: #fff;
	@include fs(16);
	font-weight: 400;
	-webkit-text-size-adjust: none;
	padding: 0 20px;
	min-height: 100vh;
	background-color: rgb(31, 31, 31);
	display: flex;
	align-items: center;
	justify-content: center;
	@include mq-down() {
		font-size: 14px;
	}
}

a {
	color: $base;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

@include mq-down() {
}

.wrap {
	border: 1px solid #464646;
	font-size: 16px;
	font-weight: 300;
	max-width: 560px;
	width: 100%;
	padding: 32px 16px;
	@include mq-down() {
		font-size: 14px;
	}
}
header {
	margin-bottom: 16px;
}
h1 {
	text-align: center;
}
main {
	line-height: 2;
	dl {
		margin-bottom: 16px;
	}
	dt {
		font-size: 20px;
		position: relative;
		&::before {
			content: "";
			background-color: $white;
			display: inline-block;
			position: absolute;
			top: 20px;
			left: -40px;
			width: 32px;
			height: 1px;
		}
		@include mq-down() {
			font-size: 18px;
			&::before {
				top: 18px;
				left: -30px;
				width: 24px;
			}
		}
	}
	dd {
		p.life {
			text-indent: -44px;
			padding-left: 44px;
		}
		@include mq-down() {
			p.life {
				text-indent: -38px;
				padding-left: 38px;
			}
		}
	}
	ul {
		display: flex;
	}
	li {
		font-size: 14px;
		margin-right: 16px;
		@include mq-down() {
			font-size: 12px;
		}
	}
	a {
		color: $white;
		transition: opacity 0.3s;
		&:hover {
			opacity: 0.7;
		}
	}
	.fa-twitterm
	.fa-instagram {
		font-size: 16px;
		color: #fff;
	}
}
footer {
	color: #464646;
	font-size: 12px;
	text-align: right;
	margin-top: 16px;
}

/* //////////////////////////////////////
- IE対策
////////////////////////////////////// */
@media all and (-ms-high-contrast:none) {}