@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;500&family=Open+Sans:wght@300;400&display=swap");
/* //////////////////////////////////////
- reset
////////////////////////////////////// */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: normal;
  text-decoration: none;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

ul,
ol {
  list-style: none;
  list-style-position: inside;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

/* //////////////////////////////////////
- common
////////////////////////////////////// */
body {
  font-family: 'Open Sans', 'Noto Sans JP', sans-serif;
  line-height: 1;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  -webkit-text-size-adjust: none;
  padding: 0 20px;
  min-height: 100vh;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.wrap {
  border: 1px solid #464646;
  font-size: 16px;
  font-weight: 300;
  max-width: 560px;
  width: 100%;
  padding: 32px 16px;
}

@media screen and (max-width: 768px) {
  .wrap {
    font-size: 14px;
  }
}

header {
  margin-bottom: 16px;
}

h1 {
  text-align: center;
}

main {
  line-height: 2;
}

main dl {
  margin-bottom: 16px;
}

main dt {
  font-size: 20px;
  position: relative;
}

main dt::before {
  content: "";
  background-color: #ffffff;
  display: inline-block;
  position: absolute;
  top: 20px;
  left: -40px;
  width: 32px;
  height: 1px;
}

@media screen and (max-width: 768px) {
  main dt {
    font-size: 18px;
  }
  main dt::before {
    top: 18px;
    left: -30px;
    width: 24px;
  }
}

main dd p.life {
  text-indent: -44px;
  padding-left: 44px;
}

@media screen and (max-width: 768px) {
  main dd p.life {
    text-indent: -38px;
    padding-left: 38px;
  }
}

main ul {
  display: flex;
}

main li {
  font-size: 14px;
  margin-right: 16px;
}

@media screen and (max-width: 768px) {
  main li {
    font-size: 12px;
  }
}

main a {
  color: #ffffff;
  transition: opacity 0.3s;
}

main a:hover {
  opacity: 0.7;
}

main .fa-twitterm
.fa-instagram {
  font-size: 16px;
  color: #fff;
}

footer {
  color: #464646;
  font-size: 12px;
  text-align: right;
  margin-top: 16px;
}

/* //////////////////////////////////////
- IE対策
////////////////////////////////////// */
